import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 6-6-6-6\\@70% 1RM`}</p>
    <p>{`Band Pull Aparts 12-12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`9-S2OH (135/95)`}</p>
    <p>{`15-Burpees`}</p>
    <p>{`21-Calorie Row`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts February 23rd!  Sign up now at:
Games.crossfit.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      